import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

const Post = ({ pageContext }) => {
  const { title, date, data } = pageContext

  console.log()
  return (
    <Layout lang={data.language.slug} translationPost={data.translations}>
      <SEO
        title={
          pageContext?.data?.data?.seo.title
            ? pageContext?.data?.data?.seo.title
            : pageContext?.data?.title
        }
        description={
          pageContext?.data?.data?.seo.metaDesc &&
          pageContext?.data?.data?.seo.metaDesc
        }
      />

      <section className="stories stories-single global__padd">
        <PostCard
          image={pageContext.data.featuredImage?.node.sourceUrl}
          time={date.split("T")[0].split("-").reverse().join(".")}
          title={title}
          shareUrl={pageContext.data.uri}
          isHorizontal
          isHeader
          isPL={pageContext.data.language.code === "PL"}
        />
        <div
          className="stories__content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </section>
    </Layout>
  )
}

export default Post
